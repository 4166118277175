import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Parallax from '../component/parallax'
import Getintouch from '../component/get-in-touch';
import Footer from '../component/footer';

import content_one from '../component/img/industries/data/content-one.png'
import content_two from '../component/img/industries/data/content-two.png'

import Features from '../component/features';

function Data() {
    useEffect(() => {
        AOS.init();
    })
    return (
        <div>
            <Row className="pt-5" id="data-landing" style={{ margin: 0, padding: 0 }}>
                <Col lg={1}></Col>
                <Col className="mx-auto my-auto">
                    <h6 id="fhir-icon"><span class="material-icons">
                        donut_large
                    </span></h6>
                    <span className="text-white" id="fhir-heading">Datareveal - DATA</span><br />
                    <span className="text-white" id="fhir-p">Data Quality Anomaly Detection</span>
                    <h2 className="text-white mt-2" id="fhir-sub-heading">Auto Detect Hard Coded ETL Pipeline Code Detection and Fixing</h2>
                </Col>
                <Col lg={1}></Col>
            </Row>

            <Row className="text-center p-4" style={{ margin: 0, padding: 0 }} id="product-page-content">
                <p className="mb-3">DR-DATA QUALITY ANOMALY DETECTION</p>
                <h2 className="mb-3">DATAREVEAL - ETL Process in Detail</h2>
                <img src={content_one} alt="data datareveal" className="img-fluid" data-aos="zoom-in"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="1200" />
            </Row>
            <hr class="progress" />
            <Row className="text-center p-4" style={{ margin: 0, padding: 0 }} id="product-page-content">
                <p className="mb-3">DR-DATA QUALITY ANOMALY DETECTION</p>
                <h2 className="mb-3">DATAREVEAL - Modern ETL Process in Detail</h2>
                <img src={content_two} alt="data datareveal" className="img-fluid" data-aos="zoom-in"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="1200" />
            </Row>

            <Row className="mt-2" style={{ margin: 0, padding: 0 }}>
                <Parallax parallax_content="Auto detect hard coded ETL pipeline code fix which enables operational value /avoid production disturbance, avoids retraining the model. Real Time Streaming Data in the pipeline able to work without interruption." />
            </Row>

            <Features />

            <Getintouch />

            <Footer />
        </div>

    );
}

export default Data
