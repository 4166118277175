import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useEffect } from "react";
import Slide from "../component/carousel";
import Feature from "../component/features";
import Footer from "../component/footer";
import Getintouch from "../component/get-in-touch";
import Parallax from "../component/parallax";

import serv_one from "../component/img/home-serv-one.svg";
import serv_two from "../component/img/home-serv-two.svg";
import serv_three from "../component/img/home-serv-three.svg";
import serv_four from "../component/img/home-serv-four.svg";
import serv_five from "../component/img/home-serv-five.svg";
import CarouselSlider from "../component/slider";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Slide />

      <div style={{ backgroundColor: `rgb(249, 249, 249)` }}>
        <Container className="mb-2">
          <Row className="mt-2 mb-4">
            <h3 className="text-center text-uppercase mb-3 mt-2">Service</h3>
            <Col lg={4}>
              <div className="text-center p-4 m-3" id="home-serv-div">
                <img
                  src={serv_one}
                  alt="service datareveal"
                  className="img-fluid mt-2 mb-3"
                  height="100%"
                  width="18%"
                />
                <h4 className="mb-4">VISION</h4>
                <p>
                  Video Analysis, Image Processing, Robotic Surgery and
                  Intelligent OCR to Extract Structured Data from unstructured
                  images, videos and documents.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center p-4 m-3" id="home-serv-div">
                <img
                  src={serv_two}
                  alt="service datareveal"
                  className="img-fluid mt-2 mb-3"
                  height="100%"
                  width="18%"
                />
                <h4 className="mb-4">LANGUAGE</h4>
                <p>
                  NLP-NLU-NLG, Chat Analytics, Topic Modelling, Customer
                  Segmentation, Compare the similarity of new input documents to
                  the documents used to train your NLP models.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="text-center p-4 m-3" id="home-serv-div">
                <img
                  src={serv_three}
                  alt="service datareveal"
                  className="img-fluid mt-2 mb-3"
                  height="100%"
                  width="18%"
                />
                <h4 className="mb-4">LEARNING</h4>
                <p>
                  Machine Learning, Deep Learning, Reinforcement Learning [RL]
                  and Graph-Based models to detect patterns, extract actionable
                  insights, and Optimize Decision-Making.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4} className="mx-auto">
              <div className="text-center p-4 m-3" id="home-serv-div">
                <img
                  src={serv_four}
                  alt="service datareveal"
                  className="img-fluid mt-2 mb-3"
                  height="100%"
                  width="18%"
                />
                <h4 className="mb-4">API DEVELOPMENT</h4>
                <p>
                  FHIR-HL7 Conversion, Email Invoice Classification, Genomic DNA
                  API, IoT Transportation Real-Time Vehicle Tracking/Anomaly
                  Detection, Crime Prevention API.
                </p>
              </div>
            </Col>
            <Col lg={4} className="mx-auto">
              <div className="text-center p-4 m-3" id="home-serv-div">
                <img
                  src={serv_five}
                  alt="service datareveal"
                  className="img-fluid mt-2 mb-3"
                  height="100%"
                  width="18%"
                />
                <h4 className="mb-4">PRODUCT DEVELOPMENT</h4>
                <p>
                  Incident Linkage Detection Tool, Patient Readmission
                  Prediction Tool/App, Data Quality Anomaly Detection Tool.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Parallax parallax_content="Enable software with human like vision to analyze images, videos, and documents across real world use cases." />

      <Feature />
      <CarouselSlider />
      <Getintouch />
      <Footer />
    </div>
  );
}
