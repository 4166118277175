import React from 'react'
import { Row, Col } from 'react-bootstrap'

var parallax_content ={
    color:`#fff`,
    fontWeight: `400`
}

export default function Parallax(props) {
    return (
        <div>
            <Row id="parallax-row" className="mb-2">
                <Col>
                    <h4 className="p-5 text-center" style={parallax_content}>{props.parallax_content}</h4>
                </Col>
            </Row>
        </div>
    )
}

