import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Heading from '../component/heading'
import Getintouch from '../component/get-in-touch'
import Footer from '../component/footer'

import content_one from '../component/img/industries/oil/content-one.jpg'
import content_two from '../component/img/industries/oil/content-two.jpg'
import content_three from '../component/img/industries/oil/content-three.jpg'

function Oil() {
    useEffect(() => {
        AOS.init();
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Row className="pt-5" id="oil-landing" style={{ margin: 0, padding: 0 }}>
                <Col lg={1}></Col>
                <Col className="mx-auto my-auto">
                    <h6 id="fhir-icon"><span class="material-icons">
                        local_gas_station
                    </span></h6>
                    <span className="text-white" id="fhir-heading">Datareveal - OIL &amp; ENERGY</span><br />
                    <span className="text-white" id="fhir-p">Agility at Scale</span>
                    <h2 className="text-white mt-2" id="fhir-sub-heading">Armed with AI, operators can better understand their reservoirs and minimize geologic risk</h2>
                </Col>
                <Col lg={1}></Col>
            </Row>

            <Row className="mt-3" style={{ margin: 0, padding: 0 }}>
                <Col lg={12} md={12} xs={12} sm={12}>
                    <Heading title="LET DR-AI HANDLE YOUR FAX AND MAIL"
                        sub="Your people have better things to do." />
                </Col>
            </Row>

            <Container>
                <Row>
                    <Col lg={6} md={12} sm={12} xs={12} className="my-auto p-3" data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={content_one} alt="transportation datareveal" className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 id="content_h2">
                            Field Level Safety Compliance Monitoring using Computer Vision
                        </h2>
                        <p id="content_p">
                            Oil workers must wear a safety helmet and lift the rig using two hands as per safety compliance requirements. If the rig is lifted in one hand and workers not wearing helmets, it will violate safety records. When such violations occur too often, the concerned worker needs to be sent for retraining, which causes loss of the man-hours.
                        </p>
                        <p id="content_p">
                            It is also not acceptable to safety standards if too many people are crowded at the field site of drilling instead of the required two workers. There is no monitoring facility available at the field site in such remote locations, no way of knowing how many are involved.
                        </p>
                        <p id="content_p">
                            Therefore, there is a requirement to monitor the situation, ensure only the right number of drillers engaged in the field site, and wear safety helmets. We use our Computer Vision and Embedded technology to improve the situation for oil drilling companies to comply with the safety requirements easily.
                        </p>
                    </Col>
                </Row>
                <hr class="progress" />
                <Row>
                    <Col lg={{ span: 6, order: 0 }} md={{ span: 12, order: 1 }} sm={12} xs={{ span: 12, order: 1 }} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 id="content_h2">
                            Achieving Optimal Horizontal Drill Operations
                        </h2>
                        <p id="content_p">
                            We present a novel method of predicting the onset of a slide event in horizontal drilling operations. Horizontal drilling operations attempt to create a well through a subsurface as quickly as possible by rotating a drill through the subsurface. A slide event occurs when the drill begins to inefficiently rotate through the subsurface, resulting in a significantly reduced rate of penetration.
                        </p>
                        <p id="content_p">
                            We identify controllable features that allow drill operators to mitigate or prevent slide events.
                        </p>
                    </Col>
                    <Col lg={{ span: 6, order: 1 }} md={{ span: 12, order: 0 }} sm={12} xs={{ span: 12, order: 0 }} className="p-3" data-aos="fade-left"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={content_two} alt="transportation datareveal" className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr class="progress" />
                <Row>
                    <Col lg={6} md={12} sm={12} xs={12} className="p-3" data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={content_three} alt="transportation datareveal" className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 id="content_h2">
                            Improving Gas Well Economics with Intelligent Plunger Lift Optimization Techniques
                        </h2>
                        <p id="content_p">
                            We present an approach to reducing bottom hole plunger dwell time for artificial lift systems. Lift systems are used in a process to remove contaminants from a natural gas well. A plunger is a mechanical device used to deliquefy natural gas wells by removing contaminants in the form of water, oil, wax, and sand from the wellbore.
                        </p>
                        <p id="content_p">
                            We are able to determine the exact time when the plunger reaches the bottom of the well with a 98% accuracy.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Getintouch />

            <Footer />
        </div>

    );
}

export default Oil
