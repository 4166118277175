import maria from "../img/team/maria.jpeg";
// import anil from "../img/team/anil.jpeg";
import ankit from "../img/team/ankit.jpeg";
import bala from "../img/team/bala.jpeg";
import fabio from "../img/team/fabio.png";
import faizen from "../img/team/faizen.png";
import joseph from "../img/team/joseph.jpeg";
import krish from "../img/team/krish.jpeg";
import mohammed from "../img/team/mohamed.jpeg";
import rishi from "../img/team/rishi.jpeg";
import suresh from "../img/team/suresh.jpeg";
import ishwar from "../img/team/iswar.jpeg";
import satt from "../img/team/sattanathan.jpeg";

const users = [
  {
    path: "/maria",
    person: maria,
    name: "Dr. Maria Viqar",
    role: "Cardiovascular Diseases Surgeon",
    post: "CMO and President of Data Reveal AI",
    details:
      "Dr Maria Viqar acts as our Chief Medical Officer (CMO) and leads the vision, development, and execution of Data Reveal AI's health strategy for clinical products, clinical research, and analytics. She has received her clinical training and participated in research programs at the world-renowned, prestigious Mayo Clinic in cardiovascular diseases. In addition, she has held leadership positions in clinical administration. Her areas of focus are health analytics and research, which ultimately benefit patient care at all healthcare systems. She is passionate about the use of modern technologies to improve patient lives through genomics and personalized medicine. She is thrilled about the Data Reveal AI's technology tool to make it easier for health systems and providers to connect data across sources and share that data with patients. She enjoys travelling.",
  },
  // {
  //   path: "/anil",
  //   person: anil,
  //   name: "Anil Bariki",
  //   role: "Vice President, Data Sciences",
  //   post: "CEO and President of Data Reveal AI",
  //   details:
  //     "Anil is our President and CEO of Data Reveal.AI. He leads the vision and growth strategy for the company. He brings over 25+ years of Data and Analytics experience, serving in multiple industries. Anil led the vision and strategy for various organizations to enable Data Engineering, Data Science and Advanced Analytics Platform with Hybrid Cloud strategy for building predictive models generating actionable insights that are governed and secured. Anil has MBA in strategy and an undergraduate in Mechanical Engineering. He lives with his wife and two kids in Dallas.",
  // },
  {
    path: "/ankit",
    person: ankit,
    name: "Ankit Kumar",
    role: "iOS Engineer",
    post: "Principal iOS / Mobile App Strategy Architect",
    details:
      "Ankit acts as our Principal Architect for Data Reveal.AI and leads our iOS / Mobile E2E product platforms and solutions. In addition, Ankit has experience architecting, building, and deploying AI/ML products on mobile operating systems.Ankit holds a Bachelor’s degree in Computer Science.",
  },
  {
    path: "/bala",
    person: bala,
    name: "Bala Daks",
    role: "Senior Data Scientist",
    post: "COO and Founder of Data Reveal AI",
    details:
      "Bala Daks acts as our COO (Chief Operating Officer) and brings 21+ Years’ experience in delivering programs across multiple industries managing a multi-million $$ portfolio for fortune 100 organizations. His experience includes managing and delivering projects in Health Care, Banking, Insurance, and other domains. Some of his clients are- City of Las Vegas, Health Care - Apria, Care First, CBS, Optum, Tenet Health Care, Banking- Chase, Bank of America, Sun Trust, Citi, TD, CIBC, Insurance - Hanover, Nationwide, Card Processor-Master Card, Vantiv. Bala holds a Master's Degree in Data Science and a Master's Degree in Engineering (M.Eng.).",
  },
  {
    path: "/fabio",
    person: fabio,
    name: "Dr.Fabio Savorgnan",
    role: "Cardiologist",
    post: "",
    details:
      "MD -FAPP-FACC– MSDS Cardiologist -Board Certified by American Board of Pediatrics, Neonatal and Perinatal Medicine, Critical Care Medicine, Cardiology.",
  },
  {
    path: "/faizan",
    person: faizen,
    name: '"Dr.Faizan Javed',
    role: "Senior Director Kaiser Permanente",
    post: "",
    details:
      "PhD Senior Director Kaiser Permanente Professor -SMU -Data Science- NLP.",
  },
  {
    path: "/joseph",
    person: joseph,
    name: "Dr.Joseph S Antony",
    role: "Associate Professor – Uni. of Leeds, UK",
    post: "",
    details:
      "PhD Associate Professor – University of Leeds, UK. Fellow of Royal Society UK.",
  },
  {
    path: "/kris",
    person: krish,
    name: "Kris Ghimire",
    role: "Senior Data Scientist",
    post: "Chief Data Scientist of Data Reveal AI",
    details:
      "Kris is our Chief Data Scientist and brings over 6+ years of experience in the healthcare field. He has a practical understanding of statistical modelling and machine learning techniques, and he works extensively in Python, R programming. He enjoys building the team and hanging out with friends. In his free time, he likes to fly the aeroplane and play music.Kris holds a Master’s degree in Data Science with a specialization in Machine Learning.",
  },
  {
    path: "/mohammed",
    person: mohammed,
    name: "Dr. Mohamed Ibrahim",
    role: "Senior Researcher with a Professor, Oxford University, UK",
    post: "CIO and Chief Computer Vision Scientist of Data Reveal AI",
    details:
      "Mohamed is our CIO (Chief Information Officer) and Chief Computer Vision Scientist. He brings Science, Engineering and technology execution experience to Data Reveal.AI. He currently leads multiple teams that build practical applications of visual AI in healthcare, such as designing an action recognition module for the SARAS project. Recently, he has been working closely with MOFHS team to develop unique tools that simplify the processes of integration and decoupling. Before Oxford Brookes, he worked as a computer vision engineer/architect at Huawei, focusing on machine learning techniques and software engineering in mobile phones.",
  },
  {
    path: "/rishi",
    person: rishi,
    name: "Rishhi Balakrishnan",
    role: "Principal AI/ML Engineer",
    post: "Principal AI/ML Strategy Architect",
    details:
      "Rishhi is our Principal Architect for Data Reveal.AI and leads our AI/ML E2E product platforms and solutions. Rishhi has experience architecting, building, and deploying AI/ML products across many form factors and devices, including sensor devices.Rishhi holds a Bachelor’s degree in Computer Science.",
  },
  {
    path: "/suresh",
    person: suresh,
    name: "Suresh Kumar",
    role: "Senior Cybersecurity Architect",
    post: "CISO and Vice President of Data Reveal AI",
    details:
      "Suresh Kumar acts as our CISO (Chief Information and Security Officer) for Data Reveal.AI. He brings over 25 years of experience working in Cyber Security Architecture, Risk, Compliances, and ISO Quality auditing. He leads a cross-functional team, building unique security architectures that are helping the clients to prevent and reduce cyber attacks. Previously, Suresh worked as an Enterprise Security Architect in various Federal and State government agencies, the Department of Defense, Border Security, Customs & Immigration, Taxation, Home Affairs, Transport, including AMEX Bank, Insurance, IBM and HP. He improved products and services related to security defences, digital forensics, crime prevention, cyber hacking preventions, containing data breaches, cyber-attack mitigation, risk mitigation, application security, cloud security, threat intelligence to customers by using architectural security solutions aligning with CISA, NIST, and ISO27K recommendations, closely integrated with the advanced technologies of AI and ML concepts. Suresh holds a Master of Engineering (M.Eng.) degree, specializing in Applied Electronics.",
  },
  {
    path: "/ishwar",
    person: ishwar,
    name: "Ishwar K. Sethi",
    role: "",
    post: "",
    details:
      "Ishwar K. Sethi is currently a professor in the Department of Computer Science and Engineering at Oakland University in Rochester, Michigan. He has over 45 years of experience in applying neural networks including deep learning, machine learning, pattern recognition, and image, video, and text analytics to a broad range of projects. He has successfully completed projects for US Air Force, Navy, local hospitals and industries. He has authored or coauthored over 175 journal and conference articles and has graduated 25 doctoral students. He has served on the editorial boards of several prominent journals including IEEE Trans. Pattern Analysis and Machine Intelligence, and IEEE Multimedia. He was elected IEEE Fellow in 2001 for his contributions in artificial neural networks and statistical pattern recognition and achieved the status of Life Fellow in 2012. He most recently organized the 20th International Conference on Machine Learning and Applications (ICMLA).Dr. Sethi serves as a consultant to a few local companies and helps NIH, Australian Research Council, and the Research Grants Council of Hong Kong.",
  },
  {
    path: "/satt",
    person: satt,
    name: "Mr.Sattanatha",
    role: "Manager, Database Engineering",
    post: "AWS/Azure Certified Cloud Architect",
    details:
      "Sattanathan, an IT Leader, brings more than 23 years of industry experience in the field of Bioinformatics, Education, Health, High-Tech, Pharmacy, Retail, Tele-Marketing and Transportation. He has successfully delivered more than 20 complex projects using resources in various geographical locations. His core strengths are: implementation of large-scale highly available mission critical applications using SAP, Oracle and Microsoft technologies, Platform migrations, Cloud migrations, building teams in various geographical locations, automation and operational efficiency. He holds Master of Business Administration (MBA) from University of Rhode Island, Master of Science (MS) from Florida Atlantic University and Master of Engineering (ME) from PSG College of Technology, India. He is a big fan of sports (NCAA, NBA, NFL, MLB, Tennis and Cricket). During his free time, he enjoys listening to music and practicing violin.",
  },
];

export default users;
