import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Landing from "../component/landing";
import Footer from "../component/footer";

import landing_img from "../component/img/visionlanding.png";

function Insurance() {
  return (
    <div>
      <Landing
        landing_img={landing_img}
        landing_img_alt="vision datareveal"
        landing_h2="Datareveal - Insurance"
        landing_h3="Work in progress..."
      />

      <Footer />
    </div>
  );
}

export default Insurance;
