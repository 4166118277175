import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Parallax from '../component/parallax'
import Heading from '../component/heading'
import Features from '../component/features'
//import Inssec from './ins-sec';
import Getintouch from '../component/get-in-touch';
import Footer from '../component/footer';

import product_page_content_one from '../component/img/industries/smart-city/product-content-img.jpg'

import content_one from '../component/img/industries/smart-city/content-one.jpg'
import content_two from '../component/img/industries/smart-city/content-two.jpg'
import content_three from '../component/img/industries/smart-city/content-three.png'
import content_four from '../component/img/industries/smart-city/content-four.png'


function City() {
    useEffect(() => {
        AOS.init();
    })
    return (
        <div>
            <Row className="pt-5" id="city-landing" style={{ margin: 0, padding: 0 }}>
                <Col lg={1}></Col>
                <Col className="mx-auto my-auto">
                    <h6 id="fhir-icon"><span class="material-icons">
                        location_city
                    </span></h6>
                    <span className="text-white" id="fhir-heading">Datareveal - SMART CITIES</span><br />
                    <span className="text-white" id="fhir-p">Agility at Scale</span>
                    <h2 className="text-white mt-2" id="fhir-sub-heading">The Future Begins In The Great City.</h2>
                </Col>
                <Col lg={1}></Col>
            </Row>

            <Row className="text-center p-4" style={{ margin: 0, padding: 0, backgroundColor: `#fff` }} id="product-page-content">
                <Container>
                    <p className="mb-3">THE INDUSTRIES</p>
                    <h2 className="mb-3">DATAREVEAL - IoT Analytics  goes to  Smart Cities</h2>
                    <img src={product_page_content_one} alt="product datareveal" className="img-fluid" data-aos="zoom-in"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1200" />
                </Container>
            </Row>

            <Row className="mt-2" style={{ margin: 0, padding: 0 }}>
                <Parallax parallax_content="Advanced digital technology enhances the lives of citizens and visitors." />
            </Row>

            <Row className="mt-2" style={{ margin: 0, padding: 0 }}>
                <Col lg={12} md={12} xs={12} sm={12}>
                    <Heading title="LET DR-AI HANDLE YOUR FAX AND MAIL"
                        sub="Your people have better things to do." />
                </Col>
            </Row>

            <Container>
                <Row>
                    <Col lg={6} md={12} sm={12} xs={12} className="p-3" data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={content_one} alt="smart cities datareveal" className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 id="content_h2">
                            Public Safety Enhancement
                        </h2>
                        <p id="content_p">
                            Provides the ability to monitor multiple locations, detecting alert noises and trends that the system catalogs as dangerous.
                        </p>
                        <p id="content_p">
                            The system detects unsafe situations such as vandalism in the area, gunshots, breaking glass, or screams of panic and other unsafe situations.
                        </p>
                        <p id="content_p">
                            The system detects unsafe situations such as vandalism in the area, gunshots, breaking glass, or screams of panic and other unsafe situations.
                        </p>
                    </Col>
                </Row>
                <hr class="progress" />
                <Row>
                    <Col lg={{ span: 6, order: 0 }} md={{ span: 12, order: 1 }} sm={12} xs={{ span: 12, order: 1 }} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 id="content_h2">
                            Usage Analytics Enhancement
                        </h2>
                        <p id="content_p">
                            Provides area usage information: what facilities people are using (for example: bins and park benches, or the streetlights on an avenue).
                        </p>
                        <p id="content_p">
                            Real-time information allows for the assignment of appropriate resources as needed.
                        </p>
                        <p id="content_p">
                            The system enables a collection of facility-usage statistics that allows you to understand how citizens use infrastructure and amenities, and empower you to provide services better.
                        </p>
                    </Col>
                    <Col lg={{ span: 6, order: 1 }} md={{ span: 12, order: 0 }} sm={12} xs={{ span: 12, order: 0 }} className="p-3" data-aos="fade-left"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={content_two} alt="smart cities datareveal" className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr class="progress" />
                <Row>
                    <Col lg={6} md={12} sm={12} xs={12} className="p-3" data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={content_three} alt="smart cities datareveal" className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 id="content_h2">
                            Traffic Predictions Enhancement
                        </h2>
                        <p id="content_p">
                            Camera devices track the flow of vehicles moving through an area.
                        </p>
                        <p id="content_p">
                            At the same time, data analytics generate traffic trends and predictions.
                        </p>
                        <p id="content_p">
                            Accurate statistics provide the ability for better planning and empower the mobility of the city.
                        </p>
                    </Col>
                </Row>
                <hr class="progress" />
                <Row>
                    <Col lg={{ span: 6, order: 0 }} md={{ span: 12, order: 1 }} sm={12} xs={{ span: 12, order: 1 }} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 id="content_h2">
                            Safe Driving Enhancement
                        </h2>
                        <p id="content_p">
                            Detects unsafe driving (example: cars driving wrong way).
                        </p>
                        <p id="content_p">
                            Allows city personnel to react.
                        </p>
                        <p id="content_p">
                            Data analytics detect traffic trends and detect risk sectors, which helps to improve infrastructure to reduce future accidents.
                        </p>
                    </Col>
                    <Col lg={{ span: 6, order: 1 }} md={{ span: 12, order: 0 }} sm={12} xs={{ span: 12, order: 0 }} className="p-3" data-aos="fade-left"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={content_four} alt="smart cities datareveal" className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Features />
            {/* <Row>
        <Inssec/> 
    </Row>  */}
            <Getintouch />
            <Footer />
        </div>

    );
}

export default City
