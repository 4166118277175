import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Footer from '../component/footer'
import Getintouch from '../component/get-in-touch'

export default class Healthcare extends Component {
    render() {
        return (
            <div>
                <Row className="pt-5" id="healthcare-landing" style={{ margin: 0, padding: 0 }}>
                    <Col lg={1}></Col>
                    <Col className="mx-auto my-auto">
                        <h6 id="fhir-icon"><span class="material-icons">
                            api
                        </span></h6>
                        <span className="text-white" id="fhir-heading">Datareveal - HEALTHCARE</span><br />
                        <span className="text-white" id="fhir-p">Agility at Scale</span>
                        <h2 className="text-white mt-2" id="fhir-sub-heading">DR-Healthcare is the maintenance or improvement of health via the prevention, diagnosis, treatment, amelioration, or cure of disease, illness, injury, and other physical and mental impairments in people.</h2>
                    </Col>
                    <Col lg={1}></Col>
                </Row>

                <Row className="text-center p-5" style={{ margin: 0, padding: 0 }}>
                    <h4 className="mb-2">DR-Predictive Analytics in Healthcare-Hospital Readmission(HRRP)</h4>
                    <p>Predictive analytics, an early step in leveraging AI, uses historical data to forecast clinical, operational, and financial needs in different areas of an organization, such as staffing, resources, patient outcomes, and high-risk patient groups. With predictive analytics providing data-driven visibility around needs, health systems can better allocate resources and prepare for different scenarios (e.g., an ICU nearing full capacity), decreasing guesswork and scrambling when unforeseen situations occur. Accurate predictive modeling also lays the foundation for advanced AI beyond predictive analytics, including retrospective comparisons and prescriptive optimization.</p>
                </Row>

                <Container>
                    {/* 1 */}
                    <Row className="p-3" style={{ margin: 0, padding: 0 }}>
                        <Col xxl xl lg md={6} sm={12}>
                            <div className="mb-4" id="healthDiv">
                                <div className="p-4" id="health-serv-div-one">
                                    <p id="health-serv-div-p">Claims fraud<br /> detection</p>
                                </div>
                                <div className="p-3">
                                    <p>using AI to stop $100 Billion in Fraudlent Healthcare Claims</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl xl lg md sm={12}>
                            <div className="mb-4" id="healthDiv">
                                <div className="p-4" id="health-serv-div-two">
                                    <p id="health-serv-div-p">claims denials<br /> management</p>
                                </div>
                                <div className="p-3">
                                    <p>Streamlining the Claims Process<br /> with AI</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl xl lg md={6} sm={12}>
                            <div className="mb-4" id="healthDiv">
                                <div className="p-4" id="health-serv-div-three">
                                    <p id="health-serv-div-p">prediciting hospital<br /> acquired infections</p>
                                </div>
                                <div className="p-3">
                                    <p>Saving Lives with<br /> AI</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* 2 */}
                    <Row className="p-3" style={{ margin: 0, padding: 0 }}>
                        <Col xxl xl lg md={6} sm={12}>
                            <div className="mb-4" id="healthDiv">
                                <div className="p-4" id="health-serv-div-four">
                                    <p id="health-serv-div-p">prediciting icu<br /> transfers</p>
                                </div>
                                <div className="p-3">
                                    <p>Saving Lives by Catching Patients before the crash with AI</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl xl lg md sm={12}>
                            <div className="mb-4" id="healthDiv">
                                <div className="p-4" id="health-serv-div-five">
                                    <p id="health-serv-div-p">medical<br /> testing</p>
                                </div>
                                <div className="p-3">
                                    <p>Prediciting Medical Test Results<br /> with AI</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl xl lg md={6} sm={12}>
                            <div className="mb-4" id="healthDiv">
                                <div className="p-4" id="health-serv-div-six">
                                    <p id="health-serv-div-p">improving clinical<br /> workflow</p>
                                </div>
                                <div className="p-3">
                                    <p>AI Provides a Helping Hand to<br /> Clinicians</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* 3 */}
                    <Row className="p-3" style={{ margin: 0, padding: 0 }}>
                        <Col xxl xl lg md={6} sm={12}>
                            <div className="mb-4" id="healthDiv">
                                <div className="p-4" id="health-serv-div-seven">
                                    <p id="health-serv-div-p">Project intake and<br /> prioritization</p>
                                </div>
                                <div className="p-3">
                                    <p>It helps ensure that companies are pursuing the right projects those that are aligned with their objectives and goals.</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl xl lg md sm={12}>
                            <div className="mb-4" id="healthDiv">
                                <div className="p-4" id="health-serv-div-eight">
                                    <p id="health-serv-div-p">Project<br /> kickoff</p>
                                </div>
                                <div className="p-3">
                                    <p>The project kickoff is an opportunity to establish common goals and the purpose in completing the work</p>
                                </div>
                            </div>
                        </Col>
                        <Col xxl xl lg md={6} sm={12}>
                            <div className="mb-4" id="healthDiv">
                                <div className="p-4" id="health-serv-div-nine">
                                    <p id="health-serv-div-p">Model<br /> development</p>
                                </div>
                                <div className="p-3">
                                    <p>Model development is an iterative process, derived, tested and built upon until a model fitting the desired criteria is built</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Getintouch />

                <Footer />
            </div >
        )
    }
}
