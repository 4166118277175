import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ExternalLink } from "react-external-link";

import maria from "../component/img/team/maria.jpeg";
import mohamed from "../component/img/team/mohamed.jpeg";
import krish from "../component/img/team/krish.jpeg";
import bala from "../component/img/team/bala.jpeg";
// import suresh from "../component/img/team/suresh.jpeg";
import senthil from "../component/img/team/senthil.jpeg";
import rishi from "../component/img/team/rishi.jpeg";
import ankit from "../component/img/team/ankit.jpeg";
import joseph from "../component/img/team/joseph.jpeg";
import faizen from "../component/img/team/faizen.png";
import fabio from "../component/img/team/fabio.png";
import ishwar from "../component/img/team/iswar.jpeg";
import sattanathan from "../component/img/team/sattanathan.jpeg";

import Footer from "../component/footer";
import Getintouch from "../component/get-in-touch";

function About() {
  return (
    <div className="mt-5 mt-sm-0">
      <Row
        className="pt-5"
        id="about-landing"
        style={{ margin: 0, padding: 0 }}>
        <Col lg={1}></Col>
        <Col className="mx-auto my-auto">
          <h6 id="fhir-icon">
            <span class="material-icons">groups</span>
          </h6>
          <span className="text-white" id="fhir-heading">
            Datareveal - TEAM
          </span>
          <br />
          <span className="text-white" id="fhir-p">
            ARCHITECTING INTELLIGENCE™
          </span>
          <h2
            className="text-white mt-2"
            id="fhir-sub-heading"
            style={{ lineHeight: `1.5em`, fontSize: `1.5em` }}>
            Datareveal AI is a Texas based technology company on a mission to
            build artificial intelligence for the real world. We leverage
            advances in computer vision, natural language processing, and
            machine learning to help organizations process, manage, and leverage
            their unstructured data to streamline manual tasks, make better data
            driven decisions, and drive business value.
          </h2>
        </Col>
        <Col lg={1}></Col>
      </Row>

      {/* leadership */}
      <Row className="p-5" style={{ margin: 0, padding: 0 }}>
        <Col>
          <h2 className="text-center text-uppercase">LEADERSHIP</h2>
        </Col>
      </Row>
      <Row className="p-3" style={{ margin: 0, padding: 0 }}>
        <Col xxl xl lg md={6} sm={12}>
          <div className="text-center mb-4" id="teammateDiv">
            <Link to="/maria" style={{ textDecoration: `none` }}>
              <img
                src={maria}
                alt="team datareveal"
                height="58%"
                width="100%"
              />
            </Link>
            <div className="mt-3 p-3">
              <h5>Dr.Maria Viqar</h5>
              <p>CMO and President</p>
              <ExternalLink
                href="https://www.linkedin.com/in/maria-viqar-md-facc-fhrs-0b5b2b53/"
                target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </ExternalLink>
              <Link to="/maria" className="ms-5" id="teammateDiv-span">
                Read Bio
              </Link>
            </div>
          </div>
        </Col>
        <Col xxl xl lg md sm={12}>
          <div className="text-center mb-4" id="teammateDiv">
            <Link to="/satt" style={{ textDecoration: `none` }}>
              <img
                src={sattanathan}
                alt="team datareveal"
                height="58%"
                width="100%"
              />
            </Link>
            <div className="mt-3 p-3">
              <h5>Mr.Sattanathan</h5>
              <p>Manager,Database Engineer</p>
              <ExternalLink href="#" target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </ExternalLink>
              <Link to="/satt" className="ms-5" id="teammateDiv-span">
                Read Bio
              </Link>
            </div>
          </div>
        </Col>
        {/* <Col xxl xl lg md sm={12}>
          <div className="text-center mb-4" id="teammateDiv">
            <Link to="/bala" style={{ textDecoration: `none` }}>
              <img src={bala} alt="team datareveal" height="58%" width="100%" />
            </Link>
            <div className="mt-3 p-3">
              <h5>Bala Daks</h5>
              <p>Founder / COO</p>
              <ExternalLink
                href="https://www.linkedin.com/in/balasubramaniam-dakshinamoorthi-2794757/"
                target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </ExternalLink>
              <Link to="/bala" className="ms-5" id="teammateDiv-span">
                Read Bio
              </Link>
            </div>
          </div>
        </Col> */}
        <Col xxl xl lg md={6} sm={12}>
          <div className="text-center mb-4" id="teammateDiv">
            <Link to="/mohammed" style={{ textDecoration: `none` }}>
              <img
                src={mohamed}
                alt="team datareveal"
                height="58%"
                width="100%"
              />
            </Link>
            <div className="mt-3 p-3">
              <h5>Dr.Mohamed Ibrahim</h5>
              <p>CIO and Chief CV Scientist</p>
              <ExternalLink
                href="https://www.linkedin.com/in/mimohamed/"
                target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </ExternalLink>
              <Link to="/mohammed" className="ms-5" id="teammateDiv-span">
                Read Bio
              </Link>
            </div>
          </div>
        </Col>
        <Col xxl xl lg md sm={12}>
          <div className="text-center mb-4" id="teammateDiv">
            <Link to="/kris" style={{ textDecoration: `none` }}>
              <img
                src={krish}
                alt="team datareveal"
                height="58%"
                width="100%"
              />
            </Link>
            <div className="mt-3 p-3">
              <h5>Kris Ghimire</h5>
              <p>Chief Data Scientist</p>
              <ExternalLink
                href="https://www.linkedin.com/in/kristech/"
                target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </ExternalLink>
              <Link to="/kris" className="ms-5" id="teammateDiv-span">
                Read Bio
              </Link>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="p-3" style={{ margin: 0, padding: 0 }}>
        <Col xxl xl lg md sm={12}>
          <div className="text-center mb-4" id="teammateDiv">
            <Link to="#" style={{ textDecoration: `none` }}>
              <img
                src={senthil}
                alt="team datareveal"
                height="58%"
                width="100%"
              />
            </Link>
            <div className="mt-3 p-3">
              <h5>Senthil</h5>
              <p>President</p>
              <ExternalLink
                href="https://www.linkedin.com/in/senthil-kumar-theagarajan-91136616/"
                target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </ExternalLink>
              <Link to="#" className="ms-5" id="teammateDiv-span">
                Read Bio
              </Link>
            </div>
          </div>
        </Col>
        <Col xxl xl lg md sm={12}>
          <div className="text-center mb-4" id="teammateDiv">
            <Link to="/rishi" style={{ textDecoration: `none` }}>
              <img
                src={rishi}
                alt="team datareveal"
                height="58%"
                width="100%"
              />
            </Link>
            <div className="mt-3 p-3">
              <h5>Rishhi Balakrishnan</h5>
              <p>Principal AI/ML Strategy Architect</p>
              <ExternalLink
                href="https://www.linkedin.com/in/rishhibala"
                target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </ExternalLink>
              <Link to="/rishi" className="ms-5" id="teammateDiv-span">
                Read Bio
              </Link>
            </div>
          </div>
        </Col>
        <Col xxl xl lg md sm={12}>
          <div className="text-center mb-4" id="teammateDiv">
            <Link to="/ankit" style={{ textDecoration: `none` }}>
              <img
                src={ankit}
                alt="team datareveal"
                height="58%"
                width="100%"
              />
            </Link>
            <div className="mt-3 p-3">
              <h5>Ankit Kumar</h5>
              <p>iOS/Mobile App Strategy Architect</p>
              <ExternalLink
                href="https://www.linkedin.com/in/ankit-rk/"
                target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </ExternalLink>
              <Link to="/ankit" className="ms-5" id="teammateDiv-span">
                Read Bio
              </Link>
            </div>
          </div>
        </Col>
        <Col xxl xl lg md sm={12}></Col>
      </Row>

      {/* ADVISORY BOARD */}
      <Row style={{ margin: 0, padding: 0 }}>
        <Col>
          <h2 className="text-center text-uppercase mt-2">ADVISORY BOARD</h2>
        </Col>
      </Row>

      <Row className="p-lg-5 gx-5" style={{ margin: 0, padding: 0 }}>
        <Col xxl={12} xl={12} lg={12} sm={12}>
          <Row>
            <Col xxl xl lg md={6} sm={12}>
              <div className="text-center mb-4" id="teammateDiv">
                <Link to="/joseph" style={{ textDecoration: `none` }}>
                  <img
                    src={joseph}
                    alt="team datareveal"
                    height="58%"
                    width="100%"
                  />
                </Link>
                <div className="mt-3 p-3">
                  <h5>Dr.Joseph S Antony</h5>
                  <p>...</p>
                  <ExternalLink
                    href="https://eps.leeds.ac.uk/chemical-engineering/staff/264/dr-s-joseph-antony"
                    target="_blank">
                    <i class="fab fa-linkedin-in"></i>
                  </ExternalLink>
                  <Link to="/joseph" className="ms-5" id="teammateDiv-span">
                    Read Bio
                  </Link>
                </div>
              </div>
            </Col>
            <Col xxl xl lg md={6} sm={12}>
              <div className="text-center mb-4" id="teammateDiv">
                <Link to="/faizan" style={{ textDecoration: `none` }}>
                  <img
                    src={faizen}
                    alt="team datareveal"
                    height="58%"
                    width="100%"
                  />
                </Link>
                <div className="mt-3 p-3">
                  <h5>Dr.Faizan Javed</h5>
                  <p>...</p>
                  <ExternalLink
                    href="https://www.linkedin.com/in/faizanjaved/"
                    target="_blank">
                    <i class="fab fa-linkedin-in"></i>
                  </ExternalLink>
                  <Link to="/faizan" className="ms-5" id="teammateDiv-span">
                    Read Bio
                  </Link>
                </div>
              </div>
            </Col>
            <Col xxl xl lg md={6} sm={12}>
              <div className="text-center mb-4" id="teammateDiv">
                <Link to="/fabio" style={{ textDecoration: `none` }}>
                  <img
                    src={fabio}
                    alt="team datareveal"
                    height="58%"
                    width="100%"
                  />
                </Link>
                <div className="mt-3 p-3">
                  <h5>Dr.Fabio Savorgnan</h5>
                  <p>...</p>
                  <ExternalLink
                    href="https://www.linkedin.com/in/fabio-savorgnan-46266769/"
                    target="_blank">
                    <i class="fab fa-linkedin-in"></i>
                  </ExternalLink>
                  <Link to="/fabio" className="ms-5" id="teammateDiv-span">
                    Read Bio
                  </Link>
                </div>
              </div>
            </Col>
            <Col xxl xl lg md={6} sm={12}>
              <div className="text-center mb-4" id="teammateDiv">
                <Link to="/ishwar" style={{ textDecoration: `none` }}>
                  <img
                    src={ishwar}
                    alt="team datareveal"
                    height="58%"
                    width="100%"
                  />
                </Link>
                <div className="mt-3 p-3">
                  <h5>Dr.Ishwar Sethi</h5>
                  <p>...</p>
                  <ExternalLink
                    href="https://www.linkedin.com/in/ishwar-sethi-8414a9/"
                    target="_blank">
                    <i class="fab fa-linkedin-in"></i>
                  </ExternalLink>
                  <Link to="/ishwar" className="ms-5" id="teammateDiv-span">
                    Read Bio
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* shareholders */}
      {/* <Row className="p-5 pt-0" style={{ margin: 0, padding: 0 }}>
        <Col>
          <h2 className="text-center text-uppercase">SHAREHOLDERS</h2>
        </Col>
      </Row>
      <Row className="p-3" style={{ margin: 0, padding: 0 }}>
        <Col xxl xl={3} lg={3} md={6} sm={12}>
          <Link to="/satt" style={{ textDecoration: `none` }}>
            <div className="text-center mb-4" id="teammateDiv">
              <img
                src={sattanathan}
                alt="team datareveal"
                height="58%"
                width="100%"
              />
              <div className="mt-3 p-3">
                <h5>Mr.Sattanathan</h5>
                <p>Manager,Database Engineering</p>
                <ExternalLink href="#" target="_blank">
                  <i class="fab fa-linkedin-in"></i>
                </ExternalLink>
                <Link to="/satt" className="ms-5" id="teammateDiv-span">
                  Read Bio
                </Link>
              </div>
            </div>
          </Link>
        </Col>
      </Row> */}
      <Getintouch />
      <Footer />
    </div>
  );
}

export default About;
