import React, { Component } from 'react'
import Content from '../component/content'
// import Slide from '../component/carousel'
import Feature from '../component/features'
import Footer from '../component/footer'
import Getintouch from '../component/get-in-touch'
import Landing from '../component/landing'
import Pagecontent from '../component/page-content'
import Parallax from '../component/parallax'

import landing_img from '../component/img/extract-platform/api/api-landing.png'

import gen_vision_one from '../component/img/extract-platform/api/api-serv-one.png'
import gen_vision_two from '../component/img/extract-platform/api/api-serv-two.png'
import gen_vision_three from '../component/img/extract-platform/api/api-serv-three.png'

import content_one from '../component/img/extract-platform/api/content-one.jpg'
import content_two from '../component/img/extract-platform/api/content-two.jpg'
import content_three from '../component/img/extract-platform/api/content-three.jpg'

export default class Apipage extends Component {
    render() {
        return (
            <div>
                <Landing
                    landing_img={landing_img} landing_img_alt="vision datareveal"
                    landing_h2="Datareveal Extract - API Development"
                    landing_h3="Datareavel API is a basic building block of procedures and functions that enable the mobile app development that access data and features of other applications, services. When writing a new program, a developer does not have to begin from scratch to build a core application."
                />
                <Pagecontent
                    vision_content_h3="API Development"
                    vision_content_h6="Datareveal"
                    vision_page_content_p="Datareveal API is a basic building block of procedures and functions that enable the mobile app development that access data and features of other applications, services. When writing a new program, a developer does not have to begin from scratch to build a core application."

                    general_page_one="Our developers always utilize best practices when integrating and implementing APIs. we harness the power of popular Microsoft platforms, including Azure, Jitterbit, Dell Boomi, Anypoint, Funnel, and more, adding superior web functionality and integrate disparate business systems with existing apps."
                    general_page_two="We implement internal and external API development solutions that preemptively solve issues regarding communications, content, data sharing, data revealing, business logic, and microservices. The APIs that we configure are compatible with all types of mobile devices, databases, search engines, and intranet systems."
                    general_page_three="Our custom API integration solutions encompass everything from development and integration to documentation and deployment. We also provide continuous API maintenance services to ensure that everything is running smoothly and securely, developing API architectures custom key encryptions for security."

                    page_content_img_one={gen_vision_one} page_content_img_alt_one="vision datareveal"
                    page_content_img_two={gen_vision_two} page_content_img_alt_two="vision datareveal"
                    page_content_img_three={gen_vision_three} page_content_img_alt_three="vision datareveal"
                />
                <Parallax
                    parallax_content="The importance of Datareveal APIs from a technical standpoint, they allow the capabilities of one computer program to be used by another. They are a means by which two different programs are able to communicate."
                />
                <Content
                    content_img_one={content_one} content_img_one_alt="vision datareveal"
                    content_img_two={content_two} content_img_two_alt="vision datareveal"
                    content_img_three={content_three} content_img_three_alt="vision datareveal"

                    vision_head_one="API DEVELOPMENT PLATFORMS"
                    vision_head_two="API AS A SERVICE"
                    vision_head_three="API TESTING AUTOMATION"

                    vision_content_one="Our developers build specialized APIs for enterprise applications. Our APIs are designed to facilitate quick & easy access to app data, business logic, functionality, web services, and more for both new and legacy applications. Our mobile, desktop, and cloud application API development services incorporate developing service oriented architectures (SOA), browsers, web sockets, operating systems, firmware/hardware, databases, and more."

                    vision_content_two="We develop all types of APIs, including API as a Service (APIaas) and web APIs, providing REST API development, along with Java, JSON, AJAX, SOAP, HTTP/HTTPS, XML, XHTML, and EDI development services. To execute AI-based microservices, we also use HTTP/SOAP interfaces and Docker containers with added custom business logic."

                    vision_content_three="We perform automated API testing, and design & configure API integration testing platforms to effectively automate UI, security, functionality, validation, load, runtime, and other testing. We also use popular API testing platforms, like Postman to perform API tests."
                />
                <Parallax
                    parallax_content="Automation: with APIs, computers rather than people can manage the work. Through APIs, agencies can update work flows to make them quicker and more productive."
                />

                <Feature />
                {/* <Slide /> */}
                <Getintouch />
                <Footer />
            </div>
        )
    }
}
