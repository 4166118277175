import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Parallax from '../component/parallax'
import Heading from '../component/heading'
import Getintouch from '../component/get-in-touch';
import Footer from '../component/footer';

import content_one from '../component/img/industries/transport/content-one.jpg'
import content_two from '../component/img/industries/transport/content-two.jpg'
import content_three from '../component/img/industries/transport/content-three.jpg'
import content_four from '../component/img/industries/transport/transport-map.jpg'

function Transport() {
    useEffect(() => {
        AOS.init();
    })
    return (
        <div>
            <Row className="pt-5" id="transport-landing" style={{ margin: 0, padding: 0 }}>
                <Col lg={1}></Col>
                <Col className="mx-auto my-auto">
                    <h6 id="fhir-icon"><span class="material-icons">
                        local_shipping
                    </span></h6>
                    <span className="text-white" id="fhir-heading">Datareveal - TRANSPORTATION</span><br />
                    <span className="text-white" id="fhir-p">Agility at Scale</span>
                    <h2 className="text-white mt-2" id="fhir-sub-heading">Accelerate your Smart Transportation</h2>
                </Col>
                <Col lg={1}></Col>
            </Row>

            <Row className="mt-3" style={{ margin: 0, padding: 0 }}>
                <Col lg={12} md={12} xs={12} sm={12}>
                    <Heading title="LET DR-AI HANDLE YOUR FAX AND MAIL"
                        sub="Your people have better things to do." />
                </Col>
            </Row>

            <Container>
                <Row>
                    <Col lg={6} md={12} sm={12} xs={12} className="p-3" data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={content_one} alt="transportation datareveal" className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 id="content_h2">
                            Traffic Predictions
                        </h2>
                        <p id="content_p">
                            System allows for the monitoring of data and devices geospatially, as a digital twin, or graphically.
                        </p>
                        <p id="content_p">
                            Alerts can be established based on incidents, thresholds, or future predictions, and the system can also provide access to video snippets of historical events and live video streams.
                        </p>
                        <p id="content_p">
                            Accurate statistics provide ability for better planning.
                        </p>
                    </Col>
                </Row>
                <hr class="progress" />
                <Row>
                    <Col lg={{ span: 6, order: 0 }} md={{ span: 12, order: 1 }} sm={12} xs={{ span: 12, order: 1 }} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 id="content_h2">
                            Safety And Efficiency Enhancement
                        </h2>
                        <p id="content_p">
                            System detects incidents and potential disruptions improving situational awareness and operational efficiencies.
                        </p>
                        <p id="content_p">
                            Real-time information allows to handle emergency situations effectively and communicate any disruptions to commuters.
                        </p>
                        <p id="content_p">
                            Predictive analytics enable predictive maintenance and increased efficiencies.
                        </p>
                    </Col>
                    <Col lg={{ span: 6, order: 1 }} md={{ span: 12, order: 0 }} sm={12} xs={{ span: 12, order: 0 }} className="p-3" data-aos="fade-left"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={content_two} alt="transportation datareveal" className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr class="progress" />
                <Row>
                    <Col lg={6} md={12} sm={12} xs={12} className="p-3" data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={content_three} alt="transportation datareveal" className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 id="content_h2">
                            Commuter Experience Enhancement
                        </h2>
                        <p id="content_p">
                            Commuters can monitor traffic level and disruptions in realtime.
                        </p>
                        <p id="content_p">
                            Real-time information allows them to plan their journeys accordingly and avoid delays.
                        </p>
                        <p id="content_p">
                            It helps provide a better customer experience and more personalized communications.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Row className="mt-2" style={{ margin: 0, padding: 0 }}>
                <Parallax parallax_content="Designing a brighter transport future." />
            </Row>

            <Row className="text-center p-4" style={{ margin: 0, padding: 0 }} id="product-page-content">
                <p className="mb-3">Real-time GPS fleet / Truck tracking</p>
                <h2 className="mb-3">DATAREVEAL - Live Tracking of Trucks / Vehicles</h2>
                <img src={content_four} alt="transport datareveal" className="img-fluid" />
            </Row>

            <Getintouch />

            <Footer />
        </div>

    );
}

export default Transport
