import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Content from '../component/content'
import Parallax from '../component/parallax'
import Landing from '../component/landing'
import Feature from '../component/features'
import Footer from '../component/footer'
import Getintouch from '../component/get-in-touch'

import landing_img from '../component/img/extract-platform/language/language-landing.png'

import content_one from '../component/img/extract-platform/language/content-one.jpg'
import content_two from '../component/img/extract-platform/language/content-two.jpg'
import content_three from '../component/img/extract-platform/language/content-three.jpg'

export default class Language extends Component {
    render() {
        return (
            <div>
                <Landing
                    landing_img={landing_img} landing_img_alt="language datareveal"
                    landing_h2="Datareveal Extract - Language"
                    landing_h3="Comprehend text and natural language to automate data capture, analysis, and entry."
                />

                <Container>
                    <Row className="mt-2">
                        <Col>
                            <h6 className="mb-2" id="page_content_h6">Datareveal</h6>
                            <h3 id="page_content_h3">Language</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <div id="language_service_one">
                                <p className="ps-2">Automates document classification, content extraction, text analysis and data entry into downstream software, storage, and RPA systems.Models can be called individually, or assembled and orchestrated with Extract Vision and Learning models to configure customized solutions to your business challenges.</p>
                            </div>
                        </Col>
                        <Col lg={4} id="language_service_two" className="my-auto ps-lg-5">
                            <p><i class="fas fa-globe-asia"></i> &nbsp;Natural Language Processing</p>
                            <p><i class="fas fa-file-alt"></i> &nbsp;Document Classification</p>
                            <p><i class="fas fa-comment-dots"></i> &nbsp;Speech-to-Text</p>
                            <p><i class="fas fa-biohazard"></i> &nbsp;Topic Modeling</p>
                        </Col>
                        <Col lg={4} id="language_service_three" className="my-auto">
                            <p><i class="fas fa-plus-square"></i> &nbsp;Natural Language Generation</p>
                            <p><i class="fas fa-text-height"></i> &nbsp;Text Extraction</p>
                            <p><i class="fas fa-chart-pie"></i> &nbsp;Text Analysis</p>
                            <p><i class="fas fa-smile-beam"></i> &nbsp;Sentiment Analysis</p>
                        </Col>
                    </Row>
                </Container>

                <Parallax
                    parallax_content="Enable your software to read, your people to focus on higher value work, and your enterprise to save time and money."
                />

                <Content
                    content_img_one={content_one} content_img_one_alt="learning datareveal"
                    content_img_two={content_three} content_img_two_alt="learning datareveal"
                    content_img_three={content_two} content_img_three_alt="learning datareveal"

                    vision_head_one="Identify and extract content from virtually any type of document"
                    vision_head_two="Leverage extracted data to derive actionable business insights"
                    vision_head_three="Get the Right Information to the Right Place, Right Away"

                    vision_content_one="It can process hundreds of documents out of the box—including common insurance documents like ACORD forms, IRS 1040s and W-2, and many types of unstructured medical reports and legal pleadings—and be quickly configured to learn new document types. Extracted information and insights can be routed to RPA bots, entered directly into enterprise systems, and analyzed and operationalized by Extract Learning models."

                    vision_content_two="Extract’s text analysis models can distill meaningful insights from mountains of unstructured customer data. Foundation AI can apply text analysis models to any large body of textual information, including emails, call center logs, customer reviews and social media streams."

                    vision_content_three="Extract leverages machine learning and pattern recognition to classify emails and correspondence, and text analysis and extraction to capture key information. Foundation AI can integrate Extract to enter data directly into most systems of record, and route the correspondence to the appropriate team members.Extract has helped forward thinking enterprises re-imagine their email-driven processes, enabling reductions in response time, increased NPS, and a sustainable competitive advantage over their competitors."
                />

                <Feature />
                {/* <Slide /> */}
                <Getintouch />
                <Footer />
            </div>
        )
    }
}
