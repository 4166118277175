import React, { Component } from "react";
import { Container, Row, Col, Form, FloatingLabel } from "react-bootstrap";
import Footer from "../component/footer";
import Recaptcha from "react-recaptcha";
import { ExternalLink } from "react-external-link";
// import emailjs from 'emailjs-com'

import c from "../component/img/contact-landing.jpg";

export default class Contact extends Component {
  constructor() {
    super();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.getvalue = this.getvalue.bind(this);
    this.state = {
      isVerified: true,
      firstname: "",
      lastname: "",
      email: "",
      number: "",
      comments: "",
    };
  }

  recaptchaLoaded() {
    console.log("Captcha successfully loaded");
  }

  //get the value from text box and store it in state variable
  getvalue(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  //pass the data to php as json to send the email
  handleSubmit() {
    if (this.state.isVerified) {
      fetch("https://www.datareveal.ai/dr/try.php", {
        mode: "no-cors",
        method: "post",
        headers: {
          "Content-Type": "text/html",
        },

        body: JSON.stringify({
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          email: this.state.email,
          number: this.state.number,
          comments: this.state.comments,
        }),
      });
      alert("You have successfully submitted!");
      this.setState({
        firstname: "",
        lastname: "",
        email: "",
        number: "",
        comments: "",
      });
    } else {
      alert("Please verify that you are a human!");
    }
  }

  render() {
    return (
      <>
        <Container fluid style={{ backgroundColor: "#321E41" }}>
          <div>
            <Row>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                className="order-sm-2 order-md-1 order-lg-1 order-xl-1 order-xxl-1">
                <div
                  className="p-5 mt-5 align-self-center"
                  style={{ color: "#fff" }}>
                  <h6> CONTACT US </h6>
                  <h1> Contact our support team </h1>
                  <h4> Need to get in touch with the team ? we 're all ears</h4>
                </div>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                style={{ margin: 0, padding: 0 }}>
                <img
                  src={c}
                  alt="contact datareveal"
                  height="100%"
                  width="100%"
                />
              </Col>
            </Row>
          </div>
        </Container>

        {/* content section */}
        <Container>
          <Row className="p-2">
            <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
              <div className="p-5 mt-2">
                <h6>let 's chat</h6>
                <h1>who do you need to get in touch with ?</h1>
              </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
              <div className="p-3 mt-2">
                <h2> Support </h2>
                <h6>
                  We have a support portal that has the answers to dozens of the
                  most common questions about Datareveal!Check it out here.
                </h6>
                <h6 className="mt-3">
                  If you don’ t find what you need, please submit a support
                  request through our help center or chat us through the info
                  @datareveal.ai.{" "}
                </h6>{" "}
              </div>
              <div className="p-3">
                <h2> Business Development </h2>
                <h6>
                  Would you like to partner with Datareveal ? Get connected with
                  our Partner team at info @datareveal.ai
                </h6>
              </div>
              <div className="p-3">
                <h2> General Questions </h2>{" "}
                <h6>
                  {" "}
                  Have a general question for us ? Contact us at info
                  @datareveal.ai.{" "}
                </h6>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid style={{ backgroundColor: "#F5F4F1" }}>
          <Row className="p-lg-5">
            <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
              <div className="p-5">
                <h6> Our Office </h6>
                <h3 className="mt-3">Datareveal</h3>
                <h5 className="mt-3">
                  {" "}
                  <span class="material-icons">email</span>{" "}
                  <ExternalLink
                    href="mailto:info@datareveal.ai"
                    style={{ textDecoration: `none`, color: `#212529` }}>
                    info @datareveal.ai
                  </ExternalLink>
                </h5>
                <h6 className="mt-3">
                  {" "}
                  5050 Quorum Drive, Suite 700, Dallas, TX 75254, USA{" "}
                </h6>
                <h6 className="mt-3"> USA - +1 704 206 9793 </h6>
                <h6 className="mt-3">
                  {" "}
                  Suite 207, 4430 Bathurst Street, North York, Toronto, ON
                  M3H3S3, Canada{" "}
                </h6>
                <h6 className="mt-3"> CA - +1 647 781 5080 </h6>
                <h6 className="mt-3">
                  {" "}
                  No 102, Bazulla Road, T Nagar, Chennai - 600017, India{" "}
                </h6>
                <h6 className="mt-3"> IN - +91 994 013 1888 </h6>
              </div>
            </Col>

            <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
              <div className="p-lg-3">
                <div className="p-3 text-center">
                  <h2> Send a message </h2>
                </div>{" "}
                {/* <Form className="p-3" onSubmit={this.handleSubmit}> */}{" "}
                {/* <Form className="p-3" > */}
                <Row className="g-2">
                  <Col>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="First Name">
                      <Form.Control
                        type="text"
                        placeholder="first name"
                        name="firstname"
                        required
                        onChange={this.getvalue}
                        value={this.state.firstname}
                      />
                    </FloatingLabel>{" "}
                  </Col>
                  <Col>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Last Name">
                      <Form.Control
                        type="text"
                        placeholder="last name"
                        name="lastname"
                        required
                        onChange={this.getvalue}
                        value={this.state.lastname}
                      />
                    </FloatingLabel>{" "}
                  </Col>{" "}
                </Row>
                <Form.Floating className="mb-3 mt-3">
                  <Form.Control
                    id="floatingInputCustom"
                    type="email"
                    placeholder="name@example.com"
                    name="email"
                    required
                    value={this.state.email}
                    onChange={this.getvalue}
                  />
                  <label htmlFor="floatingInputCustom"> Email address </label>{" "}
                </Form.Floating>
                <Form.Floating className="mb-3">
                  <Form.Control
                    id="floatingPasswordCustom"
                    type="tel"
                    placeholder="Mobile number"
                    name="number"
                    required
                    value={this.state.number}
                    onChange={this.getvalue}
                  />{" "}
                  <label htmlFor="floatingPasswordCustom">
                    {" "}
                    Mobile number{" "}
                  </label>
                </Form.Floating>
                <FloatingLabel
                  controlId="floatingTextarea"
                  label="Comments"
                  className="mb-3">
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    name="comments"
                    required
                    onChange={this.getvalue}
                    value={this.state.comments}
                  />{" "}
                </FloatingLabel>
                <div className="text-center p-3">
                  <div className="ms-lg-5 ps-lg-5 ps-3 ms-sm-5 ps-sm-5 ps-md-0 ms-md-0">
                    <Recaptcha
                      sitekey="6LcL9mEdAAAAALtQ89AZ4hhZ2wE8v9gj9C4dxaL1"
                      render="explicit"
                      onloadCallback={this.recaptchaLoaded}
                      verifyCallback={this.verifyCallback}
                    />{" "}
                  </div>
                  <button
                    className="btn btn-primary mt-2"
                    onClick={this.handleSubmit}>
                    {" "}
                    SUBMIT{" "}
                  </button>{" "}
                </div>{" "}
                {/* </Form> */}{" "}
              </div>
            </Col>
          </Row>{" "}
        </Container>

        <Footer />
      </>
    );
  }
}
