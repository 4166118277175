import React from "react";
import "./css/navbar.css";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "./img/logo.png";

export default function Navigationbar() {
  return (
    <div>
      {" "}
      <Navbar expand="lg" fixed="top" id="navbar">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            {" "}
            <img
              src={logo}
              width="100"
              height="45"
              className="d-inline-block align-top ms-lg-5"
              alt="Datareveal logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="mx-auto">
              <Nav.Link as={Link} to="/" id="nav_links">
                home
              </Nav.Link>
              <NavDropdown
                title="Extract Platform"
                id="navbarScrollingDropdown"
                className="nav_dropdown_head">
                <NavDropdown.Item as={Link} to="/api" id="nav_links">
                  api development
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/learning" id="nav_links">
                  learning
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/language" id="nav_links">
                  language
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/product" id="nav_links">
                  product development
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/vision" id="nav_links">
                  vision
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="industries"
                id="navbarScrollingDropdown"
                className="nav_dropdown_head">
                <NavDropdown.Item as={Link} to="/bank" id="nav_links">
                  banking
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/crypto" id="nav_links">
                  crypto&blockchain
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/cybersecurity" id="nav_links">
                  cybersecurity
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/data" id="nav_links">
                  data
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/healthcare" id="nav_links">
                  healthcare
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/insurance" id="nav_links">
                  insurance
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/legal" id="nav_links">
                  legal
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/oil" id="nav_links">
                  Oil &amp; Energy
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/city" id="nav_links">
                  smart cities
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/transport" id="nav_links">
                  transportation
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="products"
                id="navbarScrollingDropdown"
                className="nav_dropdown_head">
                <NavDropdown.Item as={Link} to="/genomics" id="nav_links">
                  genomics dna api
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/fhir" id="nav_links">
                  hl-7 fhir api adapter
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/about" id="nav_links">
                about us
              </Nav.Link>
              <Nav.Link as={Link} to="/contact" id="nav_contact_link">
                contact us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
