import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'


var content_h2 = {
    textTransform: `uppercase`,
    color: `#000`,
    fontSize: `1.4em`
}

var content_p = {
    textAlign: `justify`,
    color: `#000`,
    fontSize: `1.02em`,
    fontWeight: `400`,
    textIndent: `3em`
}

export default function Content(props) {
    useEffect(() => {
        AOS.init();
    })
    return (
        <div>
            <Container>
                <Row>
                    <Col lg={6} md={12} sm={12} xs={12} className="p-3" data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={props.content_img_one} alt={props.content_img_one_alt} className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 style={content_h2}>
                            {props.vision_head_one}
                        </h2>
                        <p style={content_p}>
                            {props.vision_content_one}
                        </p>
                    </Col>
                </Row>
                <hr class="progress" />
                <Row>
                    <Col lg={{ span: 6, order: 0 }} md={{ span: 12, order: 1 }} sm={12} xs={{ span: 12, order: 1 }} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 style={content_h2}>
                            {props.vision_head_two}
                        </h2>
                        <p style={content_p}>
                            {props.vision_content_two}
                        </p>
                    </Col>
                    <Col lg={{ span: 6, order: 1 }} md={{ span: 12, order: 0 }} sm={12} xs={{ span: 12, order: 0 }} className="p-3" data-aos="fade-left"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={props.content_img_two} alt={props.content_img_two_alt} className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr class="progress" />
                <Row>
                    <Col lg={6} md={12} sm={12} xs={12} className="p-3" data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="1600">
                        <div id="content-img">
                            <div id="content-img-inner">
                                <img src={props.content_img_three} alt={props.content_img_three_alt} className="img-fluid" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12} className="my-auto p-md-4 p-sm-3 p-4">
                        <h2 style={content_h2}>
                            {props.vision_head_three}
                        </h2>
                        <p style={content_p}>
                            {props.vision_content_three}
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}


