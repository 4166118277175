import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Parallax from "../component/parallax";
import Getintouch from "../component/get-in-touch";
import Footer from "../component/footer";
import Heading from "../component/heading";

import content_one from "../component/img/industries/cybersecurity/image-two.PNG";
import content_two from "../component/img/industries/cybersecurity/image-four.PNG";
import content_three from "../component/img/industries/cybersecurity/image-five.PNG";
import content_four from "../component/img/industries/cybersecurity/image-seven.PNG";

import subcontent_one from "../component/img/industries/cybersecurity/image-one.PNG";

function Cybersecurity() {
  useEffect(() => {
    AOS.init();
  });
  return (
    <div className="mt-5 mt-sm-0 mt-md-0 mt-lg-0">
      <Row
        className="pt-5"
        id="cybersecurity-landing"
        style={{ margin: 0, padding: 0 }}>
        <Col lg={1}></Col>
        <Col className="mx-auto my-auto">
          <h6 id="fhir-icon">
            <span class="material-icons">verified_user</span>
          </h6>
          <span className="text-white" id="fhir-heading">
            Datareveal - CYBERSECURITY
          </span>
          <br />
          <span className="text-white" id="fhir-p">
            AI-based security solutions
          </span>
          <h2 className="text-white mt-2" id="fhir-sub-heading">
            AI is a powerful tool for protecting against cybersecurity attacks.
            In the Internet Age, with hackers’ ability to commit theft or cause
            harm remotely, shielding assets and operations from those who intend
            harm has become more difficult than ever.
          </h2>
        </Col>
        <Col lg={1}></Col>
      </Row>

      {/* 1 */}
      <Row className="mt-3" style={{ margin: 0, padding: 0 }}>
        <Col lg={12} md={12} xs={12} sm={12}>
          <Heading
            title="LET DR-BUILDING THE AI CYBERSECURITY OF THE FUTURE"
            sub="Your people have better things to do."
          />
        </Col>
      </Row>

      <Container>
        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="p-3"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1600">
            <div id="content-img">
              <div id="content-img-inner">
                <img
                  src={subcontent_one}
                  alt="transportation datareveal"
                  className="img-fluid"
                />
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="my-auto p-md-4 p-sm-3 p-4">
            <h2 id="content_h2">
              Organizations are increasing the pace of adoption of AI in
              cybersecurity
            </h2>
            <p id="content_p">
              We asked executives where they are using AI in cybersecurity in
              their organizations. The number one application was for network
              security, followed by data security and endpoint security. Since
              the network is the backbone of any IT system, it is not surprising
              to see the latest technology – AI – deployed to protect it. Data,
              a valuable commodity prized by hackers, requires protection like
              any other asset. And the presence of endpoint security in third
              place is indicative of the proliferation of endpoint devices,
              which are expected to increase to over 25 billion devices by 2021.
            </p>
          </Col>
        </Row>
      </Container>

      <Row
        className="text-center p-4"
        style={{ margin: 0, padding: 0 }}
        id="product-page-content">
        <h5 className="mb-3">
          DATAREVEAL - Organizations face an urgent need to continually ramp up
          and improve their cybersecurity. This is because the number of
          end-user devices, networks, and user interfaces continues to grow as a
          result of advances in cloud, the IoT, 5G and conversational
          interfaces.
        </h5>
        <img
          src={content_one}
          alt="data datareveal"
          className="img-fluid"
          data-aos="zoom-in"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1200"
        />
      </Row>

      {/* 2 */}
      <Row className="mt-2" style={{ margin: 0, padding: 0 }}>
        <Parallax parallax_content="How organizations are benefitting from AI in cybersecurity?" />
      </Row>

      <Container>
        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="p-3"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1600">
            <div id="content-img">
              <img
                src={content_two}
                alt="transportation datareveal"
                className="img-fluid"
              />
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="my-auto p-md-4 p-sm-3 p-4">
            <h2 id="content_h2">
              AI lowers the cost to detect and respond to breaches
            </h2>
            <p id="content_p">
              Fast response is essential to securing an organization from cyber
              attacks. With AI, the overall time taken to detect threats and
              breaches is reduced by up to 12%. AI also reduces the time taken
              to remediate a breach or implement patches in response to an
              attack by 12%. A small subset of organizations even managed to
              reduce these time metrics by greater than 15%.
            </p>
            <p id="content_p">
              Power, a leading rechargeable battery manufacturer, partnered with
              a startup to use AI to detect and autonomously respond to threats
              as they emerge. Just weeks after the solution was deployed, the
              security team was alerted to the fact that an employee had
              downloaded potentially malicious software. They were able to
              remove the threat and head off any attack in real time.
            </p>
          </Col>
        </Row>
        <hr class="progress" />
        <Row>
          <Col
            lg={{ span: 6, order: 0 }}
            md={{ span: 12, order: 1 }}
            sm={12}
            xs={{ span: 12, order: 1 }}
            className="my-auto p-md-4 p-sm-3 p-4">
            <h2 id="content_h2">
              AI makes organizations faster at responding to breaches
            </h2>
            <p id="content_p">
              It is one of the significant advantages of AI-enabled smart
              banking services. For instance, checking financial status,
              document verification, and releasing loans are risk-related
              activities for bankers. The use of AI and machine learning in
              banking can tackle this intelligently.
            </p>
            <p id="content_p">
              AI and machine learning in banking can do this task with more
              accuracy and privacy. AI-based mobile banking applications easily
              financial activities and analyze the banking data of the borrower.
              It could help bankers to identify the risks in giving loans to
              them. In addition, using the AI-driven risk assessment process,
              bankers can analyze the borrower’s behavior and thus can reduce
              the possibility of fraudulent acts.
            </p>
          </Col>
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 0 }}
            sm={12}
            xs={{ span: 12, order: 0 }}
            className="p-3"
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1600">
            <div id="content-img">
              <img
                src={content_three}
                alt="transportation datareveal"
                className="img-fluid"
              />
            </div>
          </Col>
        </Row>
      </Container>

      {/* 3 */}
      <Row className="p-4" style={{ margin: 0, padding: 0 }}>
        <p className="mb-3" style={{ color: `#0984e3` }}>
          AI results in higher efficiency for cyber analysts
        </p>
        <h5 className="mb-3">
          Cyber analysts spend considerable time going through data logs and/or
          incident timesheets. With AI helping carry that workload, cyber
          analysts can spend more quality time analyzing the incidents
          identified by the AI cybersecurity algorithms. Three in five
          executives agree that AI in cybersecurity improves the accuracy and
          efficiency of cyber analysts.
        </h5>
        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="p-3"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1600">
            <div id="content-img">
              <img
                src={content_four}
                alt="transportation datareveal"
                className="img-fluid"
              />
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="my-auto p-md-4 p-sm-3 p-4">
            <h2 id="content_h2">
              High-potential use cases for AI in cybersecurity
            </h2>
            <p id="content_p">
              Based on our analysis, we recommend a short list of five
              high-potential use cases that have low complexity and high
              benefits. Overall, 54% of organizations surveyed have already
              implemented these high impact use cases. However, another 42% of
              organizations admit they are implementing use cases which are
              highly complex and provide lower benefits. Organizations should
              focus on use cases that offer early benefits before tackling the
              more challenging use cases.
            </p>
          </Col>
        </Row>
      </Row>

      <Getintouch />

      <Footer />
    </div>
  );
}

export default Cybersecurity;
