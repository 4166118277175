import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'

import feature_one from '../component/img/feature-one.png'
import feature_two from '../component/img/feature-two.png'
import feature_three from '../component/img/feature-three.png'
import feature_four from '../component/img/feature-four.png'
import feature_five from '../component/img/feature-five.png'


var title = {
    textAlign: `center`,
    textTransform: `uppercase`,
    color: '#000'
}

var feature_h5 = {
    color: `#000`,
    fontSize: `1.2em`,
    fontWeight: `600`,
    textTransform: `uppercase`
}

var feature_p = {
    color: `#000`,
    fontSize: `1em`
}

export default function Features() {
    return (
        <div>
            <Container>
                <h2 style={title} className="mt-3 p-2">Features</h2>
                <Row>
                    <Col lg={6} className="text-center p-3">
                        <img src={feature_one} alt="features datareveal" height="100%" width="12%" className="img-fluid mb-2" />
                        <h5 style={feature_h5}>
                            Modular and Configurable
                        </h5>
                        <p style={feature_p}>
                            Vision, Language, and Learning models can be assembled together for intelligent process automation and insight extraction
                        </p>
                    </Col>
                    <Col lg={6} className="text-center p-3">
                        <img src={feature_two} alt="features datareveal" height="100%" width="12%" className="img-fluid mb-2" />
                        <h5 style={feature_h5}>
                            Rapid Implementation
                        </h5>
                        <p style={feature_p}>
                            Cloud-based or containerized on-prem, and easily integrated via suite of APIs and configurable input and downstream connectors
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} className="text-center p-3">
                        <img src={feature_three} alt="features datareveal" height="100%" width="12%" className="img-fluid mb-2" />
                        <h5 style={feature_h5}>
                            Self Learning
                        </h5>
                        <p style={feature_p}>
                            Pre-trained to deliver immediate value and continues to learn and improve through feedback
                        </p>
                    </Col>
                    <Col lg={6} className="text-center p-3">
                        <img src={feature_four} alt="features datareveal" height="100%" width="12%" className="img-fluid mb-2" />
                        <h5 style={feature_h5}>
                            Augmented Intelligence
                        </h5>
                        <p style={feature_p}>
                            Intuitive user interface built to optimize the relative strengths of humans and machines
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col lg={12} className="text-center p-3">
                        <img src={feature_five} alt="features datareveal" height="100%" width="12%" className="img-fluid mb-2" />
                        <h5 style={feature_h5}>
                            Best in Class Data Security
                        </h5>
                        <p style={feature_p}>
                            ISO 27001 certified and customer data encrypted and isolated in SOC2 Secure SaaS cloud environment
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

